<template>
  <div class="flex flex-col w-full">
    <div class="w-full h-full bg-gray-50 text-gray-600 px-3">
      <div class="text-indigo-800 text-md font-bold pr-2 py-2">
        Prijzen en beschikbaarheid
      </div>
      <div class="flex">
        <div class="w-3/4 flex bg-white rounded-lg pl-5 py-5">
          <div class="w-1/3">
            <label class="font-bold">
              Bruto prijs incl.
            </label>
            <div class="mt-2 relative rounded-md shadow w-44">
              <input type="number"
                     onkeydown="return event.keyCode !== 69"
                     v-model="priceB2CFirst"
                     class="form-input w-full rounded-md sm:text-sm sm:leading-tight font-bold text-indigo-600 text-right pr-6">
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-gray-400 text-sm">
                €
              </div>
            </div>
          </div>
          <div class="w-1/3">
            <label class="font-bold">
              Netto prijs
            </label>
            <div class="mt-2 relative rounded-md shadow w-44">
              <input type="number"
                     onkeydown="return event.keyCode !== 69"
                     v-model="priceB2CSecond"
                     class="form-input w-full rounded-md sm:text-sm sm:leading-tight font-bold text-indigo-600 text-right pr-6">
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-gray-400 text-sm">
                €
              </div>
            </div>
          </div>
          <div class="w-1/3">
            <label class="font-bold">
              B2B prijs
              <span class="text-sm ml-1 leading-5 text-gray-300 font-light"
                    id="price-B2B">
                            <i class="fas fa-info-circle"></i>
                        </span>
            </label>
            <div class="mt-2 relative rounded-md shadow w-44">
              <input type="number"
                     onkeydown="return event.keyCode !== 69"
                     v-model="priceB2B"
                     class="form-input w-full rounded-md sm:text-sm sm:leading-tight font-bold text-indigo-600 text-right pr-6">
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-gray-400 text-sm">
                €
              </div>
            </div>
          </div>
        </div> <!-- white price 75% -->

        <div class="w-1/4 bg-white rounded-lg ml-4">
          <div class="mr-4 pl-5 py-5">
            <div class="mb-2 font-bold">
              Beschikbaar vanaf
            </div>
            <a-date-picker id="selectDate"
                           format="DD/MM/YYYY"
                           v-model="availableDate"
                           class="w-44 border border-l-4 border-gray-300 rounded shadow">
            </a-date-picker>
          </div>
        </div> <!-- white price 25% -->
      </div> <!-- flex -->

      <!-- Kilometers & Car-Pass ---------------------------------------------------------->
      <div class="text-indigo-800 text-md font-bold pr-2 py-2">
        Kilometers en Car-Pass
      </div>
      <div class="flex">
        <div class="w-1/4 bg-white rounded-lg pl-5 py-5 mr-4">
          <div class="mb-2 font-bold">
            Gepubliceerde km-stand
          </div>
          <div class="mt-2 relative w-44">
            <input type="number"
                   onkeydown="return event.keyCode !== 69"
                   v-model="publishedKms"
                   class="form-input w-full rounded-md sm:text-sm sm:leading-tight font-bold text-indigo-600 text-right pr-10 shadow">
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-gray-400">
              km
            </div>
          </div>
        </div>
        <div class="w-3/4 flex bg-white rounded-lg pl-5 py-5">
          <div class="w-1/3">
            <div class="mb-2 font-bold">
              Reëele km-stand
            </div>
            <div class="mt-2 relative w-44">
              <input type="number"
                     onkeydown="return event.keyCode !== 69"
                     v-model="actualKms"
                     class="form-input w-full rounded-md sm:text-sm sm:leading-tight font-bold text-indigo-600 text-right pr-10 shadow">
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-gray-400 text-sm">
                km
              </div>
            </div>
          </div>

          <!-- Car-Pass ----------------------------->
          <div class="w-2/3">
            <div class="mb-2 font-bold">
              Car-Pass
            </div>
            <div class="flex text-center">
              <input type="file"
                     class="hidden"
                     ref="uploader"
                     @change="onFileChanged">
              <div type="button"
                   class="flex justify-center items-center mb-1.5 w-44 bg-gray-100 text-gray-500 font-bold rounded shadow hover:bg-indigo-200 hover:text-indigo-600 cursor-pointer"
                   @click="handleUpdateButton(key)"
                   :loading="isSelecting">
                <div class="pr-2">
                  <i v-if="buttonLoading[key]"
                     class="fas fa-spinner fa-pulse">
                  </i>
                </div>
                <div class="pr-2">
                  {{ buttonText }}
                </div>
              </div> <!-- button -->

              <!-- image -------------------------------------->
<!--              <img src="./img/logos/check-car-pass.png" class="w-36 ml-14 h-full" alt="check-car-pass">-->
            </div> <!-- flex button & img -->
          </div> <!-- w-2/3 -->
        </div> <!-- white 75% -->
      </div> <!-- flex kilometers & car-pass-->

      <!-- Kilometers & Car-Pass ---------------------------------------------------------->
      <div class="text-indigo-800 text-md font-bold pr-2 py-2">
        Garantie en locatie
      </div>
      <div class="flex">
        <div class="w-1/2 bg-white rounded-lg pl-5 py-5 mr-4">
          <div class="mb-2 font-bold">
            Garantie
          </div>
          <div class="w-2/4 flex flex-row">
            <div v-for="item in [...Array(6).keys()]"
                 v-bind:key="item"
                 @click="selectWarranty(item)"
                 :class="['cursor-pointer relative border-2 border-transparent rounded-md text-sm font-semibold py-2 px-3.5 mr-3 bg-gray-100 shadow hover:bg-indigo-600 hover:text-white transition ease-in-out duration-50',
                            selectedWarranty === item ? 'border-indigo-600 text-white bg-indigo-600' : 'text-gray-500']">
              {{item + 1}}
            </div>
          </div>
        </div>
        <div class="w-1/2 bg-white rounded-lg pl-5 py-5">
          <div class="mb-2 font-bold">
            Adres
          </div>
          <div class="mt-2 relative rounded-md w-3/4 shadow z-50">
            <combo-button icon="fal fa-map-marker-alt"
                          :title="selectedAddress === null ? 'select Address' : selectedAddress.name"
                          :items="addresses"
                          v-on:item-selected="selectAddress">
            </combo-button>
          </div>
        </div> <!-- white 50% address-->
      </div> <!-- flex warranty & address -->

      <!-- Labels ---------------------------------------------------------->
      <div class="text-indigo-800 text-md font-bold pr-2 py-2">
        Labels
      </div>
      <div class="flex">
        <div class="w-1/2 bg-white rounded-lg pl-5 py-5 mr-4">
          <div class="mb-2 font-bold">
            Officiële labels
          </div>
          <div class="pr-2">
            <button v-for="item in externalLabels"
                    v-bind:key="item.id"
                    @click="toggleExternalLabel(item)"
                    :class="['relative border-2 border-transparent rounded-md text-sm font-semibold px-2 py-1.5 mr-3.5 mb-3.5 text-gray-500 bg-gray-100 hover:text-indigo-600 hover:border-indigo-600 hover:bg-indigo-100 shadow transition ease-in-out duration-50',
                            selectedExternalLabels.includes(item.id) ? 'border-indigo-600 text-indigo-600 bg-indigo-100' : '']">
              {{ item.title }}
            </button>
          </div>
        </div>
        <div class="w-1/2 bg-white rounded-lg pl-5 py-5">
          <div class="mb-2 font-bold">
            Interne labels
          </div>
          <div class="pr-2">
            <button v-for="item in internalLabels"
                    v-bind:key="item.id"
                    @click="toggleInternalLabel(item)"
                    :class="['relative border-2 border-transparent rounded-md text-sm font-semibold px-2 py-1.5 mr-3.5 mb-3.5 text-gray-500 bg-gray-100 hover:text-indigo-600 hover:border-indigo-600 hover:bg-indigo-100 shadow transition ease-in-out duration-50',
                           selectedInternalLabels.includes(item.id) ? 'border-indigo-600 text-indigo-600 bg-indigo-100' : '']">
              {{ item.title }}
            </button>
          </div>
        </div>
      </div><!-- flex labels -->

      <!-- Publication ---------------------------------------------------------->
      <div class="text-indigo-800 text-md font-bold pr-2 py-2">
        Publicatie
      </div>
      <div class="w-full bg-white rounded-lg pl-5 py-5">
        <div class="flex">
          <label class="flex items-center">
            <input type="checkbox" :checked="isAllSelected" @click="selectAllChannels" class="form-checkbox">
            <span class="block font-bold text-gray-700 pl-2">Select all</span>
          </label>
        </div>
        <div class="flex flex-wrap justify-between items-center pr-4 mb-2">
          <div v-for="item in channels"
               v-bind:key="item.id"
               @click="toggleChannel(item)"
               :class="['relative border-2 border-transparent rounded-lg px-3 py-2 my-2 hover:border-indigo-600 shadow-lg', selectedChannels.includes(item.id) ? 'border-indigo-600 text-indigo-600 bg-indigo-100' : '']">
            <input type="image"
                   :src="item.thumbnailUrl"
                   name="saveForm"
                   class="btTxt submit w-14 outline-none"
            />
            <div v-if="selectedChannels.includes(item.id)"
                 class="absolute top-0 left-0 w-full h-full bg-indigo-100 opacity-50 flex flex-row justify-center items-center">
            </div>
          </div> <!-- foreach -->
        </div> <!-- flex -->
      </div> <!-- white publication -->

      <!-- More info ---------------------------------------------------------->
      <div class="text-indigo-800 text-md font-bold pr-2 py-2">
        Meer informatie toevoegen
      </div>
      <div class="flex">
        <div class="w-1/2 bg-white rounded-lg pl-5 py-5 mr-4">
          <div class="mb-2 font-bold">
            NL
          </div>
          <div class="pr-5">
            <vue-editor v-model="content"></vue-editor>
          </div>
        </div>
        <div class="w-1/2 bg-white rounded-lg pl-5 py-5">
          <div class="mb-2 font-bold">
            FR
          </div>
          <div class="pr-5">
            <vue-editor v-model="content"></vue-editor>
          </div>
        </div>
      </div>

      <!-- car with plates ------------------------------------------------------->
      <div class="mt-4 mb-16 bg-white rounded-lg p-4">
        <div class="flex justify-between pb-4">
          <div v-for="item in plates"
               v-bind:key="item.id"
               @click="togglePlate(item)"
               :class="['relative border-2 border-transparent rounded-lg px-3 py-2 my-2 hover:border-indigo-600 shadow-lg', selectedPlate === item ? 'border-indigo-600 text-indigo-600 bg-indigo-100' : '']">
            <input type="image"
                   :src="item.thumbnailUrl"
                   name="saveForm"
                   class="btTxt submit w-40 outline-none"
            />
            <div v-if="selectedPlate === item"
                 class="absolute top-0 left-0 w-full h-full bg-indigo-100 opacity-50 flex flex-row justify-center items-center">
            </div>
          </div>
        </div> <!-- flex -->

        <!-- canvas ------------------------------------------------------->
        <div class="relative">
<!--          <img id="benz" width="880" height="586"  src="../img/benz.jpg" alt="Benz">-->
<!--          <canvas id="myCanvas" width="880" height="586" class="absolute top-0 left-0 ">-->
<!--            Your browser does not support the HTML5 canvas tag.-->
<!--          </canvas>-->
        </div>
      </div>

    </div> <!-- main gray -->
  </div> <!-- main flex -->
</template>

<script>
export default {
  name: "Publishing",
  data: function () {
    return {
      content: 'content',
      priceB2CFirst: '',
      priceB2CSecond: '',
      priceB2B: '',
      availableDate: '',
      publishedKms: '',
      actualKms: '',
      addresses: [
        {key: 1, name: 'Address one'},
        {key: 2, name: 'Address two'},
        {key: 3, name: 'Address three'},
        {key: 4, name: 'Address four'},
      ],
      externalLabels: [
        {id: 1, title: "Audi Approved +"},
        {id: 2, title: "My Way"},
        {id: 3, title: "Audi"},
        {id: 4, title: "Volkswagen"},
        {id: 5, title: "Seat"},
        {id: 6, title: "Skoda"},
        {id: 7, title: "Porsche"},
        {id: 8, title: "Lambourghini"},
        {id: 9, title: "Bentley"},
      ],
      internalLabels: [
        {id: 1, title: "Internal one"},
        {id: 2, title: "Internal two"},
        {id: 3, title: "Internal three"},
        {id: 4, title: "Internal four"},
        {id: 5, title: "Internal five"},
        {id: 6, title: "Internal six"},
        {id: 7, title: "Internal seven"},
      ],
      channels: [
        {id: 1, thumbnailUrl: "./img/logos/dieteren-logo.png"},
        {id: 2, thumbnailUrl: "./img/logos/autotrends.png"},
        {id: 3, thumbnailUrl: "./img/logos/autoscout.png"},
        {id: 4, thumbnailUrl: "./img/logos/2dehands.jpg"},
        {id: 5, thumbnailUrl: "./img/logos/autovlan.jpeg"},
        {id: 6, thumbnailUrl: "./img/logos/vroom.jpg"},
        {id: 7, thumbnailUrl: "./img/logos/tuutuut.jpeg"},
        {id: 8, thumbnailUrl: "./img/logos/jouw-website.webp"},
      ],
      plates: [
        {id: 1, thumbnailUrl: "./img/plates/MyWay_Plaque_AUDI.png"},
        {id: 2, thumbnailUrl: "./img/plates/MyWay_Plaque_SEAT.png"},
        {id: 3, thumbnailUrl: "./img/plates/MyWay_Plaque_SKODA.png"},
        {id: 4, thumbnailUrl: "./img/plates/MyWay_Plaque_VW.png"},
      ],
      buttonLoading: [],
      defaultButtonText: 'Update',
      selectedFile: null,
      isSelecting: false,
      selectDate: null,
      selectedWarranty: null,
      selectedAddress: null,
      selectItemAddress: [],
      selectedExternalLabels: [],
      selectedInternalLabels: [],
      selectedChannels: [],
      isAllSelected: false,
      selectedPlate: null,
    }
  },
  mounted() {
    if (localStorage.priceB2CFirst) {
      this.priceB2CFirst = localStorage.priceB2CFirst;
    }
    if (localStorage.priceB2CSecond) {
      this.priceB2CSecond = localStorage.priceB2CSecond;
    }
    if (localStorage.priceB2B) {
      this.priceB2B = localStorage.priceB2B;
    }
    if (localStorage.availableDate) {
      this.availableDate = localStorage.availableDate;
    }
    if (localStorage.publishedKms) {
      this.publishedKms = localStorage.publishedKms;
    }
    if (localStorage.actualKms) {
      this.actualKms = localStorage.actualKms;
    }
  },
  watch: {
    priceB2CFirst(newpriceB2CFirst) {
      localStorage.priceB2CFirst = newpriceB2CFirst;
    },
    priceB2CSecond(newpriceB2CSecond) {
      localStorage.priceB2CSecond = newpriceB2CSecond;
    },
    priceB2B(newpriceB2B) {
      localStorage.priceB2B = newpriceB2B;
    },
    availableDate(newavailableDate) {
      localStorage.availableDate = newavailableDate;
    },
    publishedKms(newpublishedKms) {
      localStorage.publishedKms = newpublishedKms;
    },
    actualKms(newactualKms) {
      localStorage.actualKms = newactualKms;
    }
  },
  computed: {
    buttonText() {
      return this.selectedFile ? this.selectedFile.name : this.defaultButtonText;
    }
  },
  methods: {
    selectWarranty(item) {
      this.selectedWarranty = this.selectedWarranty === item ? null : item;
    },
    selectAddress(address) {
      this.selectItemAddress = this.selectItemAddress === address ? null : address;
      this.selectedAddress = address;
    },
    toggleExternalLabel: function (label) {
      if (this.selectedExternalLabels.includes(label.id)) {
        this.selectedExternalLabels = this.selectedExternalLabels.filter(l => l != label.id)
      } else {
        this.selectedExternalLabels.push(label.id)
      }
    },
    toggleInternalLabel: function (label) {
      if (this.selectedInternalLabels.includes(label.id)) {
        this.selectedInternalLabels = this.selectedInternalLabels.filter(l => l != label.id)
      } else {
        this.selectedInternalLabels.push(label.id)
      }
    },
    toggleChannel: function (channel) {
      if (this.selectedChannels.includes(channel.id)) {
        this.selectedChannels = this.selectedChannels.filter(c => c != channel.id)
      } else {
        this.selectedChannels.push(channel.id)
      }
      if (this.selectedChannels.length == this.channels.length) {
        this.isAllSelected = true
      } else {
        this.isAllSelected = false
      }
    },
    selectAllChannels() {
      this.selectedChannels = [];
      if (this.isAllSelected) {
        this.isAllSelected = false
      } else {
        for (let channel in this.channels) {
          this.selectedChannels.push(this.channels[channel].id);
        }
        this.isAllSelected = true
      }
    },
    handleUpdateButton(key) {
      this.set(this.buttonLoading, key, 1);

      const self = this;
      setTimeout(function () {
        this.set(self.buttonLoading, key, 0);
      }, 5000);

      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, { once: true })

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0]
    },
    togglePlate (plate) {
      this.selectedPlate = this.selectedPlate === plate ? null : plate;
    },
  },
}
</script>

<style scoped>

</style>